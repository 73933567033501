import { useCallback, useContext } from 'react'

import { EventsContext } from '~/events/EventsContext'
import { EventScreenNameValues } from '~/events/eventTypes'
import useTrackEvents, { SpecificEventType } from '~/events/trackers/useTrackEvents'

export type TrackCtaClickData = Omit<SpecificEventType['object_click'], 'screen_name'> & {
  screen_name?: EventScreenNameValues
}

/** This hook can be used for all kind of clicks,
 * if the click have very custom logic for tracking, we can extract it out into another hook
 * multiple functions can be used from 1 single hook like trackCTA, trackSignOut
 *  */
const useTrackClick = () => {
  const { trackEvent } = useTrackEvents()
  const { currentScreen } = useContext(EventsContext)

  /**
   * data is master set of properties across vendors, this function will decide, which client gets what properties
   * */
  const trackCta = useCallback(
    (data: TrackCtaClickData) => {
      // sometimes in 1 page there can be multiple screens, using currentScreen will override the screen name
      // thus we need to pass screen name in data if we want to track clicks for  for a different screen
      trackEvent('object_click', {
        ...data,
        screen_name: (data?.screen_name || currentScreen) as EventScreenNameValues,
      })
    },
    [currentScreen, trackEvent],
  )

  return { trackCta }
}

export default useTrackClick
