
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React from 'react'

import { BodyText3, Heading1 } from '@lumoslabs/lumosity-storybook'
import type { NextPage } from 'next'
import Trans from 'next-translate/Trans'
import Head from 'next/head'
import styled from 'styled-components'

import BasicHeader from '~/components/BasicHeader'
import ButtonLinkWithTracking from '~/components/ButtonLinkWithTracking'
import Footer from '~/components/Footer'
import { UserHelpCenterUrl } from '~/constants'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

const NotFoundPage: NextPage = () => {
  const t = useTranslationForNamespace('404')

  return (
    <>
      <Head>
        <title>{t('title')}</title>
      </Head>
      <Container>
        <BasicHeader />
        <Content>
          <Heading1 as='h1'>{t('title')}</Heading1>
          <Trans
            i18nKey={`404.message`}
            components={{
              p: <PTag />,
              HomeLink: <HomeLink kind='primary' href='/' />,
              HelpLink: <ContactLink href={UserHelpCenterUrl} />,
              ContactLink: <BodyText3 as='p' />,
            }}
          />
        </Content>
        <Footer />
      </Container>
    </>
  )
}
export default NotFoundPage

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const ContactLink = styled.a`
  color: ${({ theme }) => theme.colorTokens.text['text-interactive']};
`
const Content = styled.div`
  padding: 40px;
  text-align: center;
  flex: 1;
`

const PTag = styled.p`
  display: block;
  margin: 0;
`

const HomeLink = styled(ButtonLinkWithTracking)`
  margin-top: 20px;
`


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  